<template>
    <Modal>
        <Placeholder v-if="isLoadingComments" element="span" :classes="['status-label']" :width="20"/>
        <span v-else :class="`status-label ${question.statusColor}`">{{ question.statusText }}</span>

        <template v-if="isLoading">
            <h1>
                <Placeholder :width="70"/>
            </h1>
            <Placeholder element="time" :width="35"/>
            <p>
                <Placeholder :width="80"/>
            </p>
        </template>
        <template v-else>
            <h1>{{ question.subject }}</h1>
            <time>{{ formatDateToFnsDate(new Date(question.created_at), "d. LLLL yyyy 'kl.' HH.mm") }}</time>
            <p>{{ question.description }}</p>
            <ViewFile v-for="attachment in question.attachments" :key="attachment.id" :name="attachment.file_name"
                      :path="attachment.content_url" :local="true"/>
        </template>

        <h2>Dialog</h2>
        <template v-if="isLoadingComments">
            <div class="comment">
                <p class="columns">
                    <Placeholder element="small" :width="20"/>
                    <Placeholder element="time" :width="20"/>
                </p>
                <div class="message">
                    <Placeholder element="span" :width="60"/>
                    <br>
                    <Placeholder element="span" :width="80"/>
                    <br>
                    <Placeholder element="span" :width="40"/>
                </div>
            </div>
            <div class="comment">
                <p class="columns">
                    <Placeholder element="small" :width="15"/>
                    <Placeholder element="time" :width="20"/>
                </p>
                <div class="message">
                    <Placeholder element="span" :width="50"/>
                    <br>
                    <Placeholder element="span" :width="70"/>
                </div>
            </div>
        </template>
        <template v-else-if="!isLoadingComments && !!comments.length">
            <div class="comment" v-for="comment in comments" :key="comment.id" :class="{ agent : comment.agent }">
                <p class="columns">
                    <small>{{ comment.author }}</small>
                    <time>{{ formatDateToFnsDate(new Date(comment.created_at), "d. LLLL yyyy 'kl.' HH.mm") }}</time>
                </p>
                <div v-html="comment.html_body" class="message"></div>
                <ViewFile class="attachment" v-for="attachment in comment.attachments" :key="attachment.id"
                          :name="attachment.file_name" :path="attachment.content_url" :local="true"/>
            </div>
        </template>
        <template v-else>
            <div class="comment">
                <p>Rådgiveren har ikke svaret endnu.</p>
            </div>
        </template>

        <Form @submit="addComment" v-if="question.status !== 'solved'" :processing="processing">
            <fieldset>
                <Textarea placeholder="Svar" v-model="message" :single="true"/>
                <Upload ref="file" label="Vedhæft pdf eller billede"
                        tooltip="Vedhæft et billede som understøttelse til dit spørgsmål" v-model="file"/>
                <ActionButton label="Send" :small="true" class="half"
                              :disabled="!subscription.isActive || subscription.plan !== 'pro'"
                              :tooltip="buttonTooltip"/>
            </fieldset>
        </Form>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import useState from '@/store';
import { useRoute } from 'vue-router';
import { onMounted, reactive, toRefs } from 'vue';
import methods from '@/utils/methods';
import { httpsRequest } from '@/utils/firebase';
import Textarea from '@/components/inputs/Textarea.vue';
import Form from '@/components/Form.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import Placeholder from '@/components/Placeholder.vue';
import ViewFile from '@/components/ViewFile.vue';
import Upload from '@/components/inputs/Upload.vue';
import uploadFileToZendesk from '@/utils/zendesk';

export default {
	name: 'Question',
	components: {
		Modal,
		Form,
		Textarea,
		ActionButton,
		Placeholder,
		ViewFile,
		Upload,
	},
	setup() {
		const { modalData, user, subscription } = useState();
		const hasLocalData = Object.entries(modalData.value).length;
		const route = useRoute();
		const data = reactive({
			question: {
				id: route.params.id,
				...modalData.value,
			},
			comments: [],
			isLoading: !hasLocalData,
			isLoadingComments: true,
			author: null,
			processing: false,
		});

		onMounted(async () => {
			if (data.isLoading) {
				const getZendeskTicket = httpsRequest.httpsCallable('getZendeskTicket');
				const zendeskTicket = await getZendeskTicket({ ticket: data.question.id });
				data.question = zendeskTicket.data.result.request;
				data.isLoading = false;
			}

			const getZendeskTicketComments = httpsRequest.httpsCallable('getZendeskTicketComments');
			const response = await getZendeskTicketComments({ ticket: data.question.id });
			const { users, comments } = response.data.result;

			const agentId = users.reduce((acc, u) => u.id);
			const answered = comments.reduce((acc, comment) => comment.author_id === agentId, false);
			data.question.statusColor = answered ? 'green' : 'yellow';
			data.question.statusText = answered ? 'Besvaret' : 'Afventer svar';

			data.comments = comments.map((comment) => {
				const agent = comment.author_id === agentId;
				const author = agent ? 'Rådgiver' : 'Dig';
				return { ...comment, author, agent };
			});

			const first = data.comments.shift();
			data.question.attachments = first.attachments;

			data.isLoading = false;
			data.isLoadingComments = false;
		});

		return { ...toRefs(data), user, subscription };
	},
	mixins: [methods],
	data() {
		return {
			message: null,
			file: null,
		};
	},
	computed: {
		buttonTooltip() {
			if (this.subscription.plan === 'pro') return 'Stil spørgsmål';
			return this.subscription.isActive ? 'Opgradér til pro abonnement, for at stile et spørgsmål' : 'Tilknyt et abonnement til din konto, for at stil et spørgsmål';
		},
	},
	methods: {
		async addComment() {
			try {
				this.processing = true;

				const data = {
					email: this.user.email,
					request: this.question.id,
					comment: {
						body: this.message,
					},
				};

				const temporary = {
					author: 'Dig',
					agent: false,
					html_body: this.message,
					created_at: new Date(),
				};

				if (this.file) {
					const upload = await uploadFileToZendesk(this.file);
					data.comment.uploads = upload.token;
					temporary.attachments = upload.attachments;
				}

				const addZendeskTicketComment = await httpsRequest.httpsCallable('addZendeskTicketComment');
				const response = await addZendeskTicketComment(data);

				this.comments.push({
					...temporary,
					author_id: response.data.result.request.requester_id,
				});

				this.message = null;
				this.$refs.file.remove();

				this.processing = false;
			} catch (err) {
				console.log(err);
			}
		},
	},
};
</script>

<style scoped lang="less">
div.comment {
    border-left: 1px solid rgb(var(--grey-color));
    padding-left: 10px;
    margin-bottom: 25px;

    &:not(.agent) small {
        color: inherit;
    }

    &.agent {
        border-color: rgb(var(--primary-color));
    }

    .attachment {
        margin-top: 1.3rem;
    }
}

button.small.half:hover::before {
    transform: translate3d(0, -100%, 0);
}
</style>
